import {
    ApplicationWhoAreYouDynamicControls,
    IApplicationWhoAreYouDynamicControlsParameters,
} from '../form-controls/application-who-are-you.form-controls';
import { IApplicationWhoAreYou } from '../interfaces/application-who-are-you';
import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    InputTypes,
    LabelPositions,
    noZeroRequiredValidator,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { Validators } from '@angular/forms';
import { MetaItem } from '@mt-ng2/base-service';
import createMetaItemArrayOfnOptions from '../../../donor-portal/common/create-array-of-numeric-options.library';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { IAncestry } from '@model/interfaces/ancestry';
import { FormYesNoButtonComponent } from '@common/components/form-yes-no-button.component';
import { IStateMetaItem } from '@model/interfaces/custom/state-meta-item';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';
import { ILoggedIn } from '@mt-ng2/auth-module';

export class AdminApplicationWhoAreYouDynamicControlsPartial extends ApplicationWhoAreYouDynamicControls {

    constructor(
        applicationwhoareyouPartial?: IApplicationWhoAreYou,
        donor?: ILoggedIn,
        additionalParameters?: IApplicationWhoAreYouDynamicControlsParameters,
    ) {
        super(applicationwhoareyouPartial, donor, additionalParameters);

        (<DynamicField>this.Form.FirstName) = new DynamicField({
            formGroup: this.formGroup,
            label: 'First Name',
            name: 'FirstName',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: donor && donor.CustomOptions && donor.CustomOptions.FirstName || null,
        });
        (<DynamicField>this.Form.MiddleInitial) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Initial',
            name: 'MiddleInitial',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.maxLength(1)],
            validators: { 'maxlength': 1 },
            value: donor && donor.CustomOptions && donor.CustomOptions.MiddleInitial || null,
        });
        (<DynamicField>this.Form.LastName) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Last Name',
            name: 'LastName',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: donor && donor.CustomOptions && donor.CustomOptions.LastName || null,
        });
        (<DynamicField>this.Form.Address1) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Address 1',
            name: 'Address1',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: applicationwhoareyouPartial.Address1 || null,
        });

        (<DynamicField>this.Form.Address2) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Address 2',
            name: 'Address2',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: applicationwhoareyouPartial.Address2 || null,
        });

        (<DynamicField>this.Form.City) = new DynamicField({
            formGroup: this.formGroup,
            label: 'City',
            name: 'City',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: applicationwhoareyouPartial.City || null,
        });

        (<DynamicField>this.Form.State) = new DynamicField({
            formGroup: this.formGroup,
            label: 'State/Province',
            name: 'State',
            options: this.states,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: applicationwhoareyouPartial.StateCode
                ? this.states.find((state) => state.StateCode === applicationwhoareyouPartial.StateCode).Id
                : null,
        });

        (<DynamicField>this.Form.CountryCode) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Country',
            name: 'CountryCode',
            options: this.countries,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: applicationwhoareyouPartial.CountryCode
                ? this.countries.find((country) => country.CountryCode === applicationwhoareyouPartial.CountryCode).Id
                : this.countries.find((country) => country.CountryCode === 'US').Id,
        });

        (<DynamicField>this.Form.Province) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Province/Region/County',
            name: 'Province',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: applicationwhoareyouPartial.Province || null,
        });

        (<DynamicField>this.Form.Zip) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Zip',
            name: 'Zip',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.minLength(5), Validators.maxLength(20)],
            validators: { required: true, minlength: 5, maxlength: 20 },
            value: applicationwhoareyouPartial.Zip || null,
        });

        (<DynamicField>this.Form.SelectedTextCommunication) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Is it OK to text you?',
            name: 'SelectedTextCommunication',
            options: [
                { Id: 1, Name: 'Yes', True: true },
                { Id: 2, Name: 'No', True: false },
            ],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: applicationwhoareyouPartial.HasTextCommunication === null ? null : applicationwhoareyouPartial.HasTextCommunication ? 1 : 2,
        });

        (<DynamicField>this.Form.ApplicantPhoneNumber).label = 'Phone Number';
        (<DynamicField>this.Form.ApplicantPhoneNumber).validators = { minlength: 10, required: true };
        (<DynamicField>this.Form.ApplicantPhoneNumber).validation = [Validators.minLength(10), Validators.required];
        (<DynamicField>this.Form.ApplicantPhoneNumber).labelPosition.position = LabelPositions.Hidden;
        (<DynamicField>this.Form.ConfirmPhoneNumber).label = 'Confirm Phone Number';
        (<DynamicField>this.Form.ConfirmPhoneNumber).validators = { minlength: 10, required: true };
        (<DynamicField>this.Form.ConfirmPhoneNumber).validation = [Validators.minLength(10), Validators.required];
        (<DynamicField>this.Form.ConfirmPhoneNumber).labelPosition.position = LabelPositions.Hidden;
        (<DynamicField>this.Form.BodyStructureId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Body Structure',
            name: 'BodyStructureId',
            options: this.bodyStructures,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, noZeroRequiredValidator],
            validators: { required: true },
            value: (applicationwhoareyouPartial && applicationwhoareyouPartial.BodyStructureId) || null,
        });
        (<DynamicField>this.Form.CollegeGpaId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'College Gpa',
            name: 'CollegeGpaId',
            options: this.collegeGpas,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: (applicationwhoareyouPartial && applicationwhoareyouPartial.CollegeGpaId) || null,
        });
        (<DynamicField>this.Form.CollegeMajor) = new DynamicField({
            formGroup: this.formGroup,
            label: 'College Major',
            name: 'CollegeMajor',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.maxLength(100)],
            validators: { maxlength: 100 },
            value:
                applicationwhoareyouPartial &&
                    applicationwhoareyouPartial.hasOwnProperty('CollegeMajor') &&
                    applicationwhoareyouPartial.CollegeMajor !== null
                    ? applicationwhoareyouPartial.CollegeMajor.toString()
                    : '',
        });
        (<DynamicField>this.Form.EyeColorId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Eye Color',
            name: 'EyeColorId',
            options: this.eyeColors,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, noZeroRequiredValidator],
            validators: { required: true },
            value: (applicationwhoareyouPartial && applicationwhoareyouPartial.EyeColorId) || null,
        });
        (<DynamicField>this.Form.HairTextureId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Hair Texture',
            name: 'HairTextureId',
            options: this.hairTextures,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, noZeroRequiredValidator],
            validators: { required: true },
            value: (applicationwhoareyouPartial && applicationwhoareyouPartial.HairTextureId) || null,
        });
        (<DynamicField>this.Form.HighSchoolGpaId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'High School Gpa',
            name: 'HighSchoolGpaId',
            options: this.highSchoolGpas,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: (applicationwhoareyouPartial && applicationwhoareyouPartial.HighSchoolGpaId) || null,
        });

        (<DynamicField>this.Form.NaturalHairColorId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Natural Hair Color',
            name: 'NaturalHairColorId',
            options: this.naturalHairColors,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: (applicationwhoareyouPartial && applicationwhoareyouPartial.NaturalHairColorId) || null,
        });

        (<DynamicField>this.Form.PlaceOfBirth) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Place Of Birth',
            name: 'PlaceOfBirth',
            options: this.countries,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.Dropdown,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { 'required': true },
            value: applicationwhoareyouPartial.PlaceOfBirth
                ? this.countries.find((country) => country.CountryCode === applicationwhoareyouPartial.PlaceOfBirth).Id
                : null,
        });

        (<DynamicField>this.Form.RaceId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Race',
            name: 'RaceId',
            options: this.races,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, noZeroRequiredValidator],
            validators: { required: true },
            value: (applicationwhoareyouPartial && applicationwhoareyouPartial.RaceId) || null,
        });
        (<DynamicField>this.Form.SkinTypeId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Skin Type',
            name: 'SkinTypeId',
            options: this.skinTypes,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, noZeroRequiredValidator],
            validators: { required: true },
            value: (applicationwhoareyouPartial && applicationwhoareyouPartial.SkinTypeId) || null,
        });

        (<DynamicField>this.Form.OtherAncestryMother).label = 'Mother\'s Ancestry';
        (<DynamicField>this.Form.OtherAncestryMother).validation = [Validators.required, Validators.maxLength(250)];
        (<DynamicField>this.Form.OtherAncestryMother).validators = { maxlength: 250, required: true };

        (<DynamicField>this.Form.OtherAncestryFather).label = 'Fathers\'s Ancestry';
        (<DynamicField>this.Form.OtherAncestryFather).validation = [Validators.required, Validators.maxLength(250)];
        (<DynamicField>this.Form.OtherAncestryFather).validators = { maxlength: 250, required: true };

        (<DynamicField>this.Form.ApplicationFatherAncestries) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Father Ancestries',
            name: 'ApplicationFatherAncestries',
            options: this.ancestries,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: this.getAncestriesMultiselectValue(applicationwhoareyouPartial.FatherAncestries, this.ancestries),
        });
        (<DynamicField>this.Form.ApplicationMotherAncestries) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Mother Ancestries',
            name: 'ApplicationMotherAncestries',
            options: this.ancestries,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: this.getAncestriesMultiselectValue(applicationwhoareyouPartial.MotherAncestries, this.ancestries),
        });
        (<DynamicField>this.Form.CurrentOccupation) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Current Occupation',
            name: 'CurrentOccupation',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(100)],
            validators: { required: true, maxlength: 100 },
            value:
                applicationwhoareyouPartial &&
                    applicationwhoareyouPartial.hasOwnProperty('CurrentOccupation') &&
                    applicationwhoareyouPartial.CurrentOccupation !== null
                    ? applicationwhoareyouPartial.CurrentOccupation.toString()
                    : '',
        });
        (<DynamicField>this.Form.CharacterDescription) = new DynamicField({
            formGroup: this.formGroup,
            label: 'How would a family member or friend describe your character?',
            name: 'CharacterDescription',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(3000)],
            validators: { maxlength: 3000, required: true },
            value:
                applicationwhoareyouPartial &&
                    applicationwhoareyouPartial.hasOwnProperty('CharacterDescription') &&
                    applicationwhoareyouPartial.CharacterDescription !== null
                    ? applicationwhoareyouPartial.CharacterDescription.toString()
                    : '',
        });
        (<DynamicField>this.Form.ExerciseRoutine) = new DynamicField({
            formGroup: this.formGroup,
            label: 'What is your exercise routine?',
            name: 'ExerciseRoutine',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(3000)],
            validators: { maxlength: 3000, required: true },
            value:
                applicationwhoareyouPartial &&
                    applicationwhoareyouPartial.hasOwnProperty('ExerciseRoutine') &&
                    applicationwhoareyouPartial.ExerciseRoutine !== null
                    ? applicationwhoareyouPartial.ExerciseRoutine.toString()
                    : '',
        });
        (<DynamicField>this.Form.Favorites) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Favorite (Books, Food, Music)',
            name: 'Favorites',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(3000)],
            validators: { maxlength: 3000, required: true },
            value:
                applicationwhoareyouPartial &&
                    applicationwhoareyouPartial.hasOwnProperty('Favorites') &&
                    applicationwhoareyouPartial.Favorites !== null
                    ? applicationwhoareyouPartial.Favorites.toString()
                    : '',
        });
        (<DynamicField>this.Form.Goals) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Goals (Personal / Career)',
            name: 'Goals',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(3000)],
            validators: { maxlength: 3000, required: true },
            value:
                applicationwhoareyouPartial && applicationwhoareyouPartial.hasOwnProperty('Goals') && applicationwhoareyouPartial.Goals !== null
                    ? applicationwhoareyouPartial.Goals.toString()
                    : '',
        });
        (<DynamicField>this.Form.LanguagesSpoken) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Languages Spoken',
            name: 'LanguagesSpoken',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(3000)],
            validators: { maxlength: 3000, required: true },
            value:
                applicationwhoareyouPartial && applicationwhoareyouPartial.hasOwnProperty('LanguagesSpoken') && applicationwhoareyouPartial.LanguagesSpoken !== null
                    ? applicationwhoareyouPartial.LanguagesSpoken.toString()
                    : '',
        });

        (<DynamicField>this.Form.TalentsAbilities) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Talents/Abilities',
            name: 'TalentsAbilities',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(3000)],
            validators: { maxlength: 3000, required: true },
            value:
                applicationwhoareyouPartial &&
                    applicationwhoareyouPartial.hasOwnProperty('TalentsAbilities') &&
                    applicationwhoareyouPartial.TalentsAbilities !== null
                    ? applicationwhoareyouPartial.TalentsAbilities.toString()
                    : '',
        });
        (<DynamicField>this.Form.WhyInterestedInBecomingEggDonor) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Why are you interested in becoming an egg donor?',
            name: 'WhyInterestedInBecomingEggDonor',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(3000)],
            validators: { maxlength: 3000, required: true },
            value:
                applicationwhoareyouPartial &&
                    applicationwhoareyouPartial.hasOwnProperty('WhyInterestedInBecomingEggDonor') &&
                    applicationwhoareyouPartial.WhyInterestedInBecomingEggDonor !== null
                    ? applicationwhoareyouPartial.WhyInterestedInBecomingEggDonor.toString()
                    : '',
        });
    }

    getAncestriesMultiselectValue(value: IAncestry[], options: any[]): number[] {
        const ancestryIds = value.map((itm) => itm.Id);
        return options?.filter((itm) => ancestryIds.includes(itm.Id)).map((itm) => itm.Id);
    }
}
