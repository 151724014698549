<div class="miles-card padded" *ngIf="!isEditing && application.Id > 0">
    <h4>
        Application Details
        <button type="button" class="btn btn-primary pull-right" (click)="ValidateAndSubmitApplication()">Submit
            Application</button>
    </h4>
    <div>
        <div class="row">
            <div class="padded">
                <ngx-skeleton-loader count="25" *ngIf="!application"></ngx-skeleton-loader>
                <div *ngIf="application">
                    <ngb-tabset #formTabs type="pills">
                        <ngb-tab id="tab-preScreening">
                            <ng-template ngbTabTitle>
                                {{
                                application.ApplicationPreScreening && application.ApplicationPreScreening.Complete
                                ? 'Pre-screening'
                                : 'Pre-screening (Incomplete)'
                                }}
                            </ng-template>
                            <ng-template ngbTabContent>
                                <application-pre-screening *ngIf="application && application.ApplicationPreScreening"
                                    [preScreening]="application.ApplicationPreScreening" [application]="application"
                                    [denialReasonMapping]="DenialReasonsMapping"></application-pre-screening>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab id="tab-medicalIssues">
                            <ng-template ngbTabTitle>
                                {{
                                application.ApplicationIssue && application.ApplicationIssue.Complete
                                ? 'Medical Issues'
                                : 'Medical Issues (Incomplete)'
                                }}
                            </ng-template>
                            <ng-template ngbTabContent>
                                <medical-issues *ngIf="application && application.ApplicationIssue"
                                    [medicalIssues]="application.ApplicationIssue"
                                    [application]="application"></medical-issues>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab id="tab-familyHistory">
                            <ng-template ngbTabTitle>
                                {{
                                application.ApplicationFamilyHistory && application.ApplicationFamilyHistory.Complete
                                ? 'Family History'
                                : 'Family History (Incomplete)'
                                }}
                            </ng-template>
                            <ng-template ngbTabContent>
                                <family-history *ngIf="application && application.ApplicationFamilyHistory"
                                    [familyHistory]="application.ApplicationFamilyHistory"
                                    [application]="application"></family-history>
                                <h3 *ngIf="!application.ApplicationFamilyHistory" style="text-align: center">No Data To
                                    Display</h3>
                            </ng-template>
                        </ngb-tab>

                        <ngb-tab id="tab-whoAreYou">
                            <ng-template ngbTabTitle>
                                {{
                                application.ApplicationWhoAreYou && application.ApplicationWhoAreYou.Complete
                                ? 'Who Are You?'
                                : 'Who Are You? (Incomplete)'
                                }}
                            </ng-template>
                            <ng-template ngbTabContent>
                                <who-are-you *ngIf="application && application.ApplicationWhoAreYou"
                                    [whoAreYou]="application.ApplicationWhoAreYou" [application]="application"
                                    [donor]="donor" [preScreening]="application.ApplicationPreScreening"></who-are-you>
                            </ng-template>
                        </ngb-tab>

                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</div>