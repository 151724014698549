import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { IBirthControlType } from '@model/interfaces/birth-control-type';
import { IBodyStructure } from '@model/interfaces/body-structure';
import { IGpa } from '@model/interfaces/gpa';
import { IEducationCompletedType } from '@model/interfaces/education-completed-type';
import { IEyeColor } from '@model/interfaces/eye-color';
import { IHairTexture } from '@model/interfaces/hair-texture';
import { INaturalHairColor } from '@model/interfaces/natural-hair-color';
import { IRace } from '@model/interfaces/race';
import { ISkinType } from '@model/interfaces/skin-type';
import { IApplicationWhoAreYou } from '@model/interfaces/application-who-are-you';
import {
    ApplicationWhoAreYouDynamicControls,
    IApplicationWhoAreYouDynamicControlsParameters,
} from '@model/form-controls/application-who-are-you.form-controls';
import { ISeriousTraumaticEvent } from '@model/interfaces/serious-traumatic-event';
import { AdminApplicationWhoAreYouDynamicControlsPartial } from '@model/partials/admin-application-who-are-you-partial.form-controls';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { IStateMetaItem } from '@model/interfaces/custom/state-meta-item';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';
import { ILoggedIn } from '@mt-ng2/auth-module';

export class WhoAreYouDynamicConfig<T extends IApplicationWhoAreYou> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(
        private whoAreYou: T,
        private donor: ILoggedIn,
        private hasAcknowledgedWaiver: boolean,
        private whoAreYouMetaItems?: IPersonalInformationMetaData,
        private states?: IStateMetaItem[],
        private countries?: ICountryMetaItem[],
        private configControls?: string[],
    ) {
        super();
        const additionalParams: IApplicationWhoAreYouDynamicControlsParameters = {
            formGroup: 'ApplicationWhoAreYou',
            metaData: this.whoAreYouMetaItems,
            states: this.states,
            countries: this.countries,
            hasAcknowledgedWaiver: this.hasAcknowledgedWaiver,
        };

        const dynamicControls = new AdminApplicationWhoAreYouDynamicControlsPartial(this.whoAreYou, this.donor, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'HasAcknowldegedWaiver',
                'FirstName',
                'MiddleInitial',
                'LastName',
                'Address1',
                'Address2',
                'City',
                'StateCode',
                'Province',
                'Zip',
                'CountryCode',
                'Dob',
                'PlaceOfBirth',
                'ApplicantPhoneNumber',
                'ConfirmPhoneNumber',
                'HasTextCommunication',
                'EmergencyContactName',
                'EmergencyContactNumber',
                'BodyStructureId',
                'EyeColorId',
                'NaturalHairColorId',
                'HairTextureId',
                'SkinTypeId',
                'RaceId',
                'HighSchoolGpaId',
                'CollegeGpaId',
                'CurrentOccupation',
                'CollegeMajor',
                'TalentsAbilities',
                'Favorites',
                'CharacterDescription',
                'WhyInterestedInBecomingEggDonor',
                'Goals',
                'ExerciseRoutine',
                'LanguagesSpoken',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
